.vault-earnings {
	display: flex;
	flex: row;
	/* position: absolute; */
	width: 427px;
	height: 159px;
	left: 505px;
	top: 165px;

	background: rgba(62, 62, 62, 0.6);
	box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */

	background: linear-gradient(#3e3e3e, #3e3e3e) padding-box,
		linear-gradient(
				73.6deg,
				rgba(133, 255, 196, 0.6) 2.11%,
				rgba(92, 198, 255, 0.6) 42.39%,
				rgba(188, 133, 255, 0.6) 85.72%
			)
			border-box;
	border: 2px solid transparent;
	border-radius: 16px;
}

.vault-earnings-img {
	position: absolute;
	width: 105px;
	height: 162px;
	left: 0px;
	top: -6px;

	mix-blend-mode: normal;
}

.vault-earnings-text {
	position: relative;
	width: 157px;
	height: 20px;
	left: 128px;
	top: 46px;

	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;

	color: #757575;
}

.vault-earnings-amount {
	position: relative;
	width: 104px;
	height: 38px;
	left: 127px;
	right: 189px;
	top: 21px;
	bottom: 41px;

	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 38px;
	/* identical to box height */

	text-transform: uppercase;

	color: #ffffff;
}
