.navbar {
	position: relative;
	/* width: 1440px; */
	height: 125px;

	background: transparent;
	mix-blend-mode: normal;

	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	z-index: 10;
}

.navbar-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.navbar-logo {
	position: absolute;
	width: 33.61px;
	height: 27.6px;
	left: 62px;
	top: 43px;

	mix-blend-mode: normal;
}

.navbar-logo-text {
	position: absolute;
	width: 94px;
	height: 31px;
	left: 107.32px;
	top: 41px;

	font-family: 'Urbanist', sans-serif;
	font-style: normal;
	font-weight: 900;
	font-size: 26px;
	line-height: 31px;

	color: #ffffff;
}

.navbar-list-dashboard1 {
	position: absolute;
	width: 102px;
	height: 23px;
	left: 271px;
	top: 45px;

	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 23px;
	/* identical to box height */

	/* gard1 */

	color: #dddddd;

	mix-blend-mode: normal;
}

.navbar-list-dashboard2 {
	position: absolute;
	width: 102px;
	height: 23px;
	left: 271px;
	top: 45px;

	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 23px;
	/* identical to box height */

	/* gard1 */

	background: linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;

	mix-blend-mode: normal;
}

.navbar-list-dashboard2::after {
	background: linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%);
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	height: 2px;
	width: 100%;
}

.navbar-list-vault1 {
	position: absolute;
	width: 58px;
	height: 23px;
	left: 426px;
	top: 45px;

	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 23px;
	/* identical to box height */

	/* Grey */

	color: #dddddd;

	mix-blend-mode: normal;
}

.navbar-list-vault2 {
	position: absolute;
	width: 58px;
	height: 23px;
	left: 426px;
	top: 45px;

	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 23px;
	/* identical to box height */

	/* Grey */

	background: linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;

	text-decoration: underline solid 5px;

	mix-blend-mode: normal;
}

.navbar-list-vault2::after {
	background: linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%);
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	height: 2px;
	width: 100%;
}

.navbar-list-eth-button {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;

	position: absolute;
	width: 65px;
	height: 40px;
	left: 975px;
	top: 43px;

	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;

	/* Gray/22 */

	background-color: #1c1924;
	mix-blend-mode: luminosity;
	border-radius: 8px;

	color: #ffffff;
	opacity: 0.75;
}

.navbar-list-amount {
	text-align: left;
	vertical-align: middle;
}

.navbar-list-amount-button {
	float: left;
	display: flex;
	flex-direction: row;
	position: absolute;
	/* width: 265px; */
	width: fit-content;
	height: 40px;
	left: 1056px;
	top: 43px;

	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	align-items: center;
	vertical-align: middle;

	/* Gray/20 */

	background-color: #2f2a3c;
	mix-blend-mode: normal;
	border-radius: 8px;

	/* identical to box height, or 150% */

	font-feature-settings: 'pnum' on, 'lnum' on;

	color: #ffffff;

	mix-blend-mode: normal;
}

.navbar-list-address-button {
	float: right;
	position: relative;
	left: 8px;
	top: 0px;
	width: 177px;
	height: 40px;

	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;

	/* Gray/22 */

	background-color: #1c1924;
	border-radius: 8px;

	/* identical to box height, or 150% */

	font-feature-settings: 'pnum' on, 'lnum' on;

	/* Iris/80 */

	color: #7879f1;
}

.navbar-list-wallet-button {
	cursor: pointer;
	position: absolute;
	right: 10rem;
	top: 43px;
	display: flex;
	flex-direction: column;
	color: white;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;

	width: 185px;
	height: 50px;

	background: linear-gradient(214.02deg, #b75cff 6.04%, #671ae4 92.95%);
	/* mix-blend-mode: luminosity; */
	border-radius: 10px;

	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;

	/* identical to box height, or 150% */

	font-feature-settings: 'pnum' on, 'lnum' on;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;
}

.navbar-list-settings-button {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	gap: 8px;

	position: absolute;
	width: 51.4px;
	height: 36px;
	right: 6rem;
	top: 45px;

	/* Gray/22 */

	background: #1c1924;
	border-radius: 8px;
}
