.asset-performance {
	box-sizing: border-box;

	/* position: absolute; */
	width: 1320px;
	height: 445px;
	margin-left: 4rem;
	margin-top: 2rem;
	left: 63px;
	top: 822px;

	background: #1c1c1c;
	opacity: 0.75;
	border: 1px solid #000000;
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */

	border-radius: 16px;
}

.asset-performance-title {
	display: flex;
	flex-direction: row;
}
.asset-performance-head {
	position: relative;
	left: 24px;
	top: 24px;

	font-family: 'Sora', 'sans-serif';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	/* identical to box height, or 120% */

	font-feature-settings: 'pnum' on, 'lnum' on;

	color: #bc85ff;
}

.asset-performance-unit {
	display: flex;
	flex-direction: row;
}

.asset-performance-unit-unit1 {
	position: relative;
	left: 840px;
	top: 31px;

	margin-right: 5px;
	margin-left: 5px;

	width: 121px;
	height: 47px;
	background: #3e3e3e;
	mix-blend-mode: luminosity;
	opacity: 0.6;
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */

	border-radius: 8px;
	border: none;

	font-family: 'Sora';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	/* or 133% */

	text-align: center;
	font-feature-settings: 'pnum' on, 'lnum' on;

	color: #ffffff;

	mix-blend-mode: luminosity;
}

.asset-performance-unit-unit2 {
	position: relative;
	left: 840px;
	top: 31px;

	margin-right: 5px;
	margin-left: 5px;

	width: 121px;
	height: 47px;
	background: #3e3e3e;
	mix-blend-mode: luminosity;
	opacity: 0.6;
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */

	border-radius: 8px;
	border: none;

	font-family: 'Sora';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	/* or 133% */

	text-align: center;
	font-feature-settings: 'pnum' on, 'lnum' on;

	color: #ffffff;

	mix-blend-mode: luminosity;
}

.asset-performance-text {
	position: relative;
	left: 24px;
	top: 24px;
	font-family: 'Sora', 'sans-serif';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	/* identical to box height, or 133% */

	font-feature-settings: 'pnum' on, 'lnum' on;

	color: #ffffff;

	mix-blend-mode: normal;
}

.asset-performance-balance {
	position: relative;
	left: 24px;
	top: 24px;
	font-family: 'Sora';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 24px;
	/* identical to box height, or 100% */

	font-feature-settings: 'pnum' on, 'lnum' on;

	color: #ffffff;

	mix-blend-mode: normal;
}

.asset-performance-timeline {
	position: relative;
	left: 33px;
	top: 42px;
	display: flex;
	flex-direction: row;
}

.asset-performance-timeline-item1 {
	width: 150px;
	height: 209px;

	/* Iris/60 */

	background: #a5a6f6;
	mix-blend-mode: normal;
	opacity: 0.3;
}

.asset-performance-timeline-item2 {
	width: 150px;
	height: 209px;

	/* Iris/60 */

	background: #a5a6f6;
	mix-blend-mode: normal;
	opacity: 0.1;
}

.asset-performance-timeline-item-date {
	position: relative;
	top: 173px;
	font-family: 'Sora';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	/* identical to box height, or 133% */

	text-align: center;
	margin: auto;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: #ffffff;

	mix-blend-mode: normal;
}

.asset-performance-timeline-chart {
	width: 1050px;
	height: 209px;
	position: absolute;
	left: 0px;
}
