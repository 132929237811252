.dashboard {
	/* width: 1440px; */
	height: 100%;
	background: #080808;
	/* padding: 4rem; */
}

.bg1 {
	position: absolute;
	left: -100px;
	top: 100px;
}

.row {
	display: flex;
	width: 100%;
	align-items: center;
}

.top {
	justify-content: space-between;
	align-items: center;
	position: relative;
	margin-top: -15rem;
}

.bottom {
	justify-content: space-between;
}

.vector_container {
	margin-top: -2rem;
	position: relative;
	left: 20rem;
}
.pink_vector {
	position: relative;
	left: 6.3rem;
	bottom: 1.5rem;
}

.zigzag_line {
	margin-left: 2rem;
	margin-bottom: 5rem;
}
.wave_container {
	overflow: hidden;
	margin-top: -24rem;
}

.landing_text {
	margin-left: 4rem;
	margin-top: -4rem;
}

.wave_text {
	width: 100%;
}

.landing_text2 {
	margin-right: 4rem;
	width: 70%;
}

.landing_text h1 {
	z-index: 1;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 70px;
	color: #ffffff;
	line-height: 120%;
	margin: 0;

	margin-left: 1rem;
}

.landing_text2 h1 {
	z-index: 1;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 50px;
	color: #ffffff;
	line-height: 120%;
	margin: 0;

	margin-left: 1rem;
}

.defi_title {
	position: relative;
	right: 5rem;
}

.rem_title {
	background: linear-gradient(73.6deg, #85ffc433 2.11%, #5cc6ff33 42.39%, #bc85ff33 85.72%);
}

.text2_container {
	width: 80%;
}

.landing_text p {
	z-index: 1;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	margin-left: 1rem;
	line-height: 180%;
	color: rgba(255, 255, 255, 0.5);
}

.landing_text2 p {
	z-index: 1;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	margin-left: 1rem;
	line-height: 180%;
	color: rgba(255, 255, 255, 0.5);
}

.landing_asset {
	overflow: hidden;
}

.landing_asset2 {
	width: 70%;
	margin-left: 8rem;
}

.mock_asset {
	width: 25rem;
}

.explore_btn {
	background: linear-gradient(214.02deg, #b75cff 6.04%, #671ae4 92.95%);
	border-radius: 10px;
	color: #fff;
	cursor: pointer;
	padding: 8px 16px;
	width: 185px;
	height: 50px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
}

.mobile_asset {
	margin-right: -14rem;
}

@media only screen and (max-width: 768px) {
	.row {
		flex-direction: column;
	}
	.text-row {
		flex-direction: row;
	}

	.top {
		margin-top: 5rem;
	}
	.landing_text2 {
		margin-right: 0;
	}
	.vector_container {
		width: fit-content;
	}
}
