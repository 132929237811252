.back-button {
	/* position: absolute; */
	left: 64px;
	top: 100px;
	margin-left: 4rem;
	width: fit-content;
	height: fit-content;

	display: flex;
	flex-direction: row;

	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	/* identical to box height */

	text-align: center;
	text-transform: capitalize;

	color: #c4c4c4;

	mix-blend-mode: normal;
}

.back-button-img {
	float: left;
}

.back-button-text {
	padding-left: 13px;
	float: right;
}
