.backgroundGradient3 {
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    left: -100px;
    top: 900px;
    filter: blur(100px);
}