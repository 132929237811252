.connect-wallet{
    z-index: 1;
    box-sizing: border-box;
    
    display: flex;
    flex-direction: column;

    position: absolute;
    width: 390px;
    height: 610px;
    left: calc(50% - 390px/2);
    top: calc(50% - 552px/2);

    background: #141414;
    mix-blend-mode: normal;
    
    background: linear-gradient(#141414, #141414) padding-box,
                linear-gradient(73.6deg,
                    rgba(133, 255, 196, 0.6) 2.11%,
                    rgba(92, 198, 255, 0.6) 42.39%,
                    rgba(188, 133, 255, 0.6) 85.72%) border-box;
    border: 2px solid transparent;
    border-radius: 16px;
}

.connect-wallet-head{

    float: left;
    position: relative;
    left: 24px;

    width: 142px;
    height: 28px;

    /* Title-1 */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */

    font-feature-settings: 'pnum' on, 'lnum' on;

    /* White */

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.connect-wallet-cross{
    float: right;
    position: relative;
    right: 24px;
    top: 20px;
}

.connect-wallet-content{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 8px;

    margin-top: 23px;

    position: relative;
    width: 330px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    top: 26px;

    background: #272727;
    border-radius: 8px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    font-feature-settings: 'pnum' on, 'lnum' on;

    /* White */

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.connect-wallet-content-checkbox{
    float: left;
}

.connect-wallet-content-text{
    position: relative;
    top: -16.5px;
    float: right;
}

.connect-wallet-wallets{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 8px;

    margin-top: 23px;

    position: relative;
    width: 330px;
    height: 45px;
    margin-left: auto;
    margin-right: auto;
    top: 26px;

    background: #272727;
    border-radius: 8px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    font-feature-settings: 'pnum' on, 'lnum' on;

    /* White */

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}
