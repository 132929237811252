.nft-wallet {
	/* position: absolute; */
	width: 200px;
	height: 200px;
	left: 620px;
	top: 380px;

	display: flex;
	flex-direction: column;
}

.nft-wallet-text {
	/* position: absolute; */
	width: 209px;
	height: 40px;
	/* left: 614px;
    top: 570px; */

	font-family: 'Sora';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	text-align: center;

	color: #ffffff;
}
