.asset-overview {
	box-sizing: border-box;

	/* position: absolute; */
	width: 765px;
	height: 633px;
	left: 63px;
	top: 169px;

	padding-bottom: 24px;
	margin-left: 4rem;

	display: flex;
	flex-direction: column;

	background: #1c1c1c;
	opacity: 0.75;
	border: 1px solid #000000;
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */

	border-radius: 16px;
}

.asset-overview-head {
	position: relative;
	left: 24px;
	top: 24px;

	width: fit-content;

	font-family: 'Sora';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	/* identical to box height, or 120% */

	font-feature-settings: 'pnum' on, 'lnum' on;

	color: #bc85ff;
}

.asset-overview-statistics {
	display: flex;
	flex-direction: row;
}

.asset-overview-statistics-image {
	position: relative;
	width: 121px;
	height: 108px;
	left: 29px;
	top: 41px;

	background: #3e3e3e;
	opacity: 0.6;
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */

	border-radius: 8px;
}

.asset-overview-statistics-asset-image {
	position: relative;
	width: 78px;
	height: 71px;
	left: 20px;
	top: 19px;
}

.asset-overview-statistics-table {
	position: relative;
	width: fit-content;
	height: 24px;
	left: 142px;
	top: 41px;

	font-family: 'Sora';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	/* identical to box height, or 171% */

	text-align: left;
	font-feature-settings: 'pnum' on, 'lnum' on;

	color: #ffffff;
}

.asset-overview-statistics-table-row {
}

.asset-overview-statistics-table-row-data {
	font-weight: 400;

	font-feature-settings: 'pnum' on, 'lnum' on;

	color: #d0d0d0;
}

.asset-overview-statistics-table-row-info {
	position: relative;
	top: 1px;
}

.asset-overview-about {
	display: flex;
	flex-direction: column;

	position: relative;
	left: 29px;
	top: 50px;

	height: max-content;

	margin-bottom: 32px;
}

.asset-overview-about-head {
	/* position: relative;
    left: 29px;
    top: 50px; */

	font-family: 'Sora';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	/* identical to box height, or 133% */

	text-align: left;
	font-feature-settings: 'pnum' on, 'lnum' on;

	color: #ffffff;
}

.asset-overview-about-text {
	/* position: relative;
    left: 27px;
    top: 92px; */
	width: 572px;
	height: fit-content;

	font-family: 'Sora';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	/* or 171% */

	font-feature-settings: 'pnum' on, 'lnum' on;

	color: #d0d0d0;
}

.asset-overview-strategies {
	display: flex;
	flex-direction: column;

	position: relative;
	left: 29px;
	top: 18px;

	height: max-content;
}

.asset-overview-strategies-head {
	/* position: relative;
    left: 29px;
    top: 18px; */

	font-family: 'Sora';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	/* identical to box height, or 133% */

	text-align: left;
	font-feature-settings: 'pnum' on, 'lnum' on;

	color: #ffffff;
}

.asset-overview-strategies-text {
	/* position: relative;
    left: 27px;
    top: 18px; */
	width: 572px;
	height: fit-content;

	font-family: 'Sora';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 24px;
	/* or 171% */

	font-feature-settings: 'pnum' on, 'lnum' on;

	color: #d0d0d0;
}
