.dashboard {
	/* width: 1440px; */
	height: 100%;
	background: #080808;
}

.bg1 {
	position: absolute;
	left: -100px;
	top: 100px;
}

.dashboard_row {
	justify-content: space-evenly;
	width: 100%;
	margin-top: 4rem;
	flex-wrap: wrap;
}

.wallet_row {
	width: 100%;
	justify-content: center;
	margin-top: 2rem;
}
