.submitted{
    z-index: 1;
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    
    position:absolute;
    width: fit-content;
    height: fit-content;
    padding-bottom: 44px;
    padding-top: 29px;
    padding-left: 36px;
    padding-right: 36px;
    margin: auto;
    left: 540px;
    top: 305px;

    align-items: center;

    background: #141414;
    mix-blend-mode: normal;
    
    background: linear-gradient(#141414, #141414) padding-box,
                linear-gradient(73.6deg,
                    rgba(133, 255, 196, 0.6) 2.11%,
                    rgba(92, 198, 255, 0.6) 42.39%,
                    rgba(188, 133, 255, 0.6) 85.72%) border-box;
    border: 2px solid transparent;
    border-radius: 16px;
}

.submitted-img{
    align-items: center;
    width: 193px;
    height: 177.14px;
}

.submitted-text{
    font-family: 'Sora';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    /* identical to box height, or 100% */

    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;

    /* gard1 */

    background: linear-gradient(73.6deg, #85FFC4 2.11%, #5CC6FF 42.39%, #BC85FF 85.72%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.submitted-button{
    /* position: absolute; */
    width: 286px;
    height: 56px;
    /* left: 577px;
    top: 577px; */

    background: #393939;
    mix-blend-mode: normal;
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 8px;

    font-family: 'Sora';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    font-feature-settings: 'pnum' on, 'lnum' on;

    color: #FFFFFF;
}