.footer {
	/* position: absolute;
    width: fit-content;
    height: 18px;
    left: 66px;
    bottom: 0px; */
	padding-left: 66px;
	/* width: 1374px; */
	padding-top: 108px;

	display: flex;
	flex-direction: row;

	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	justify-content: space-between;
	/* identical to box height */

	/* Grey */

	color: #dddddd;
	background-color: #080808;

	mix-blend-mode: normal;
}

.footer_text {
	display: flex;
	align-items: center;
}

.social_wrapper {
	margin-right: 4rem;
}

.logo {
	margin-left: 1rem;
}
