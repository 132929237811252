.transaction-overview {
	/* position: absolute; */
	width: 1308px;
	height: fit-content;
	left: 62px;
	top: 368px;
	display: flex;
	flex-direction: column;

	background: #1c1c1c;
	opacity: 0.75;
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */

	border-radius: 16px;
}

.transaction-overview-head {
	position: relative;
	width: fit-content;
	height: 30px;
	left: 24px;
	top: 24px;

	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 30px;
	padding-bottom: 41px;

	color: #bc85ff;
}

.transaction-overview-table {
	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	text-align: left;
	padding-left: 31px;
	padding-bottom: 55px;
	/* identical to box height */

	color: #878787;
}

.transaction-overview-table-head {
	width: 87px;
	height: 18px;

	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	/* identical to box height */

	color: #878787;
}

.transaction-overview-table-row {
	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 23px;
	/* identical to box height */

	text-transform: capitalize;

	color: #ffffff;
}

.transaction-overview-table-asset {
	display: flex;
	flex-direction: row;
	align-items: center;
}
