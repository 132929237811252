.asset-info{

    z-index: 1;

    box-sizing: border-box;
    
    position: absolute;
    width: fit-content;
    height: fit-content;
    padding: 15px;
    left: 178px;
    top: 23px;

    background: #282828;
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */

    background: linear-gradient(#282828, #282828) padding-box,
                linear-gradient(73.6deg,
                    rgba(133, 255, 196, 0.6) 2.11%,
                    rgba(92, 198, 255, 0.6) 42.39%,
                    rgba(188, 133, 255, 0.6) 85.72%) border-box;
    border: 2px solid transparent;
    border-radius: 8px;
}

.asset-info-statistics {
    display: flex;
    flex-direction: row;
}

.asset-info-statistics-table {
    /* position: relative;
    width: fit-content;
    height: 24px;
    left: 142px;
    top: 41px; */

font-family: 'Sora';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    /* identical to box height, or 164% */

    text-align: left;
    letter-spacing: -0.05px;
    font-feature-settings: 'pnum' on, 'lnum' on;

    color: #E7E7E7;
}

.asset-info-statistics-table-row {}

.asset-info-statistics-table-row-data {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 24px;
    /* identical to box height, or 218% */

    text-align: right;
    font-feature-settings: 'pnum' on, 'lnum' on;

    color: #E7E7E7;
}