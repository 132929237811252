.success{
    z-index: 1;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    
    position: absolute;
    width: 336px;
    height: fit-content;
    left: 1040px;
    top: 100px;

    padding-left: 24px;
    padding-right: 24px;

    background: #161616;
    mix-blend-mode: normal;
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */

    background: linear-gradient(#161616, #161616) padding-box,
                linear-gradient(73.6deg,
                    rgba(133, 255, 196, 0.6) 2.11%,
                    rgba(92, 198, 255, 0.6) 42.39%,
                    rgba(188, 133, 255, 0.6) 85.72%) border-box;
    border: 2px solid transparent;
    border-radius: 16px;
}

.success-title{
    display: flex;
    flex-direction: row;
}

.success-head{
    font-family: 'Sora';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    font-feature-settings: 'pnum' on,
        'lnum' on;

    color: #85FFC4;

    mix-blend-mode: normal;
}

.success-text{
    font-family: 'Sora';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    /* or 175% */

    font-feature-settings: 'pnum' on, 'lnum' on;

    /* White */

    color: #FFFFFF;

    mix-blend-mode: normal;
}

.success-link{
    font-family: 'Sora';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */

    font-feature-settings: 'pnum' on, 'lnum' on;

    color: #85FFC4;

    mix-blend-mode: normal;
}
