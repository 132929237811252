.highestAPY {
	box-sizing: border-box;

	/* position: absolute; */
	width: 1318px;
	height: 233px;
	left: 62px;
	top: 363px;

	display: flex;
	flex-direction: column;

	background: #1c1c1c;
	opacity: 0.75;
	border: 1px solid #000000;
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */

	border-radius: 16px;
	margin-bottom: 2rem;
}

.highestAPY-head {
	position: relative;
	width: fit-content;
	height: 30px;
	left: 24px;
	top: 24px;

	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 30px;
	padding-bottom: 62px;

	color: #bc85ff;
}

.highestAPY-table {
	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	text-align: left;
	padding-left: 31px;
	padding-bottom: 55px;
	/* identical to box height */

	color: #878787;
}

.highestAPY-table-row {
	width: max-content;
	height: 20px;
	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	/* identical to box height */

	display: flex;
	flex-direction: row;

	color: #6e6b7b;
}

.highestAPY-table-asset {
	padding-right: 233px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.highestAPY-table-image {
	width: 62px;
	height: 58px;
}

.highestAPY-table-row-text {
	opacity: 1;
	width: max-content;
	/* height: 38px; */

	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	/* line-height: 38px; */
	/* identical to box height */

	text-transform: uppercase;

	color: #ffffff;
}
