.backgroundGradient1{
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    object-fit: cover;
    left: -100px;
    top: 100px;
    filter: blur(100px);
}

