.asset-transaction {
	display: flex;
	flex-direction: column;

	box-sizing: border-box;

	position: absolute;
	width: 527px;
	height: 630px;
	left: 848px;
	top: 172px;

	background: #3e3e3e;
	mix-blend-mode: normal;
	opacity: 0.75;
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */

	background: linear-gradient(#3e3e3e, #3e3e3e) padding-box,
		linear-gradient(
				73.6deg,
				rgba(133, 255, 196, 0.6) 2.11%,
				rgba(92, 198, 255, 0.6) 42.39%,
				rgba(188, 133, 255, 0.6) 85.72%
			)
			border-box;
	border: 2px solid transparent;
	border-radius: 16px;
}

.asset-transaction-button {
	display: flex;
	flex-direction: row;
}

.asset-transaction-deposit-button {
	z-index: 1;
	box-sizing: border-box;

	position: relative;
	width: 239px;
	height: 57px;
	left: 24px;
	top: 24px;

	mix-blend-mode: normal;
	border: 1px solid rgba(255, 255, 255, 0.3);
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */

	background: #3e3e3e;

	border-radius: 8px;

	font-family: 'Sora';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	/* identical to box height, or 133% */

	text-align: center;
	font-feature-settings: 'pnum' on, 'lnum' on;

	color: rgba(255, 255, 255, 0.5);

	mix-blend-mode: normal;
}

.asset-transaction-deposit-button-click {
	z-index: 1;
	box-sizing: border-box;

	position: relative;
	width: 239px;
	height: 57px;
	left: 24px;
	top: 24px;

	background: rgba(130, 130, 130, 0.6);
	mix-blend-mode: normal;
	border: 1px solid rgba(255, 255, 255, 0.3);
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */

	border-radius: 8px;

	font-family: 'Sora';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	/* identical to box height, or 133% */

	text-align: center;
	font-feature-settings: 'pnum' on, 'lnum' on;

	color: rgba(255, 255, 255, 0.5);

	mix-blend-mode: normal;
}

.asset-transaction-withdraw-button {
	box-sizing: border-box;

	position: relative;
	width: 250px;
	height: 57px;
	left: 13px;
	top: 24px;

	mix-blend-mode: normal;
	border: 1px solid rgba(255, 255, 255, 0.3);
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */

	background: #3e3e3e;

	border-radius: 8px;

	font-family: 'Sora';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	/* identical to box height, or 133% */

	text-align: center;
	font-feature-settings: 'pnum' on, 'lnum' on;

	color: rgba(255, 255, 255, 0.5);

	mix-blend-mode: normal;
}

.asset-transaction-withdraw-button-click {
	box-sizing: border-box;

	position: relative;
	width: 250px;
	height: 57px;
	left: 13px;
	top: 24px;

	background: rgba(130, 130, 130, 0.6);
	mix-blend-mode: normal;
	border: 1px solid rgba(255, 255, 255, 0.3);
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */

	border-radius: 8px;

	font-family: 'Sora';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	/* identical to box height, or 133% */

	text-align: center;
	font-feature-settings: 'pnum' on, 'lnum' on;

	color: rgba(255, 255, 255, 0.5);

	mix-blend-mode: normal;
}

.asset-transaction-wallet {
	position: absolute;
	width: 110px;
	height: fit-content;
	left: 27px;
	top: 113px;

	display: flex;
	flex-direction: column;
}

.asset-transaction-wallet-top {
	width: 480px;
	height: 24px;

	font-family: 'Sora';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */

	font-feature-settings: 'pnum' on, 'lnum' on;

	color: #dadada;
}

.asset-transaction-wallet-top-balance {
	float: right;
}

.asset-transaction-wallet-top-text {
	float: left;
}

.asset-transaction-static {
	display: flex;
	flex-direction: row;
}

.asset-transaction-image1 {
	position: relative;
	width: 27px;
	height: 25px;
	left: 16px;
	top: 45px;

	mix-blend-mode: normal;
}

.asset-transaction-wallet-input-text {
	z-index: 1;
	position: relative;
	width: 129px;
	height: 28px;
	left: 47px;
	top: 0px;

	font-family: 'Sora';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 28px;
	/* identical to box height, or 175% */

	font-feature-settings: 'pnum' on, 'lnum' on;

	/* White */

	color: #ffffff;
	opacity: 1;

	mix-blend-mode: normal;
}

.asset-transaction-wallet-input {
	box-sizing: border-box;

	position: relative;
	width: 478px;
	height: 56px;
	left: 0px;
	top: -60px;

	text-align: right;

	background: rgba(255, 255, 255, 0.1);

	background: linear-gradient(#646464, #646464) padding-box,
		linear-gradient(
				73.6deg,
				rgba(133, 255, 196, 0.6) 2.11%,
				rgba(92, 198, 255, 0.6) 42.39%,
				rgba(188, 133, 255, 0.6) 85.72%
			)
			border-box;
	border: 2px solid transparent;
	border-radius: 16px;

	color: #ffffff;
	opacity: 1;
}

input[type='text'],
input[type='password'],
textarea,
select {
	outline: none;
	font-family: 'Sora';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */

	text-align: right;
	font-feature-settings: 'pnum' on, 'lnum' on;
}

.asset-transaction-wallet-bottom {
	position: relative;
	top: -60px;
	width: 480px;
	height: fit-content;

	font-family: 'Sora';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	/* identical to box height, or 171% */

	font-feature-settings: 'pnum' on, 'lnum' on;

	color: #c4c4c4;
}

.asset-transaction-wallet-bottom-text {
	float: left;
}

.asset-transaction-wallet-bottom-btn {
	position: relative;
	top: 15px;

	float: right;
	width: 53px;
	height: fit-content;

	background: #585858;
	opacity: 1;
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */

	border-radius: 8px;
	border: none;

	text-align: center;
	font-feature-settings: 'pnum' on, 'lnum' on;

	/* Iris/60 */

	color: #a5a6f6;
}

:placeholder-shown {
	font-family: 'Sora', 'sans-serif';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 28px;
	/* identical to box height, or 175% */

	font-feature-settings: 'pnum' on, 'lnum' on;

	color: #ffffff;

	opacity: 0.5;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;
}

.asset-transaction-arrow {
	position: relative;
	left: 246px;
	top: 217px;
	width: 36px;
	height: 36px;
}

.asset-transaction-vault {
	position: absolute;
	width: 110px;
	height: fit-content;
	left: 27px;
	top: 305px;

	display: flex;
	flex-direction: column;
}

.asset-transaction-wallet-bottom-yield {
	float: right;
	font-feature-settings: 'pnum' on, 'lnum' on;

	color: #c4c4c4;

	mix-blend-mode: normal;
}

.asset-transaction-approve {
	position: relative;
	width: 478px;
	height: 56px;
	left: 24px;
	top: 390px;

	background: #69f0ae;
	mix-blend-mode: normal;
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */

	border-radius: 8px;

	font-family: 'Sora';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */

	font-feature-settings: 'pnum' on, 'lnum' on;

	color: #212121;
	border: none;
}

.asset-transaction-balance {
	position: relative;
	width: 471px;
	height: 56px;
	left: 24px;
	top: 405px;

	background: none;
	mix-blend-mode: normal;
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */

	border-radius: 8px;

	font-family: 'Sora';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */

	font-feature-settings: 'pnum' on, 'lnum' on;

	border-color: #cfcfd5;

	color: #cfcfd5;
}
