.opportunities {
	box-sizing: border-box;

	/* position: absolute; */
	width: 1320px;
	height: fit-content;
	left: 60px;
	top: 661px;

	display: flex;
	flex-direction: column;

	background: #1c1c1c;
	opacity: 0.75;
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */

	background: linear-gradient(#1c1c1c, #1c1c1c) padding-box,
		linear-gradient(
				73.6deg,
				rgba(133, 255, 196, 0.6) 2.11%,
				rgba(92, 198, 255, 0.6) 42.39%,
				rgba(188, 133, 255, 0.6) 85.72%
			)
			border-box;
	border: 2px solid transparent;
	border-radius: 16px;
}

.opportunities-head {
	position: relative;
	width: fit-content;
	height: 30px;
	left: 24px;
	top: 24px;

	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 30px;
	padding-bottom: 41px;

	color: #bc85ff;
}

.opportunities-table {
	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	text-align: left;
	padding-left: 31px;
	padding-bottom: 55px;
	/* identical to box height */

	color: #878787;
}

.opportunities-table-head {
	width: 49px;
	height: 18px;

	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	/* identical to box height */

	color: #878787;
}

.opportunities-table-row {
	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 23px;
	/* identical to box height */

	text-transform: capitalize;

	color: #ffffff;
}

.opportunities-table-asset {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.opportunities-table-image {
	width: 62px;
	height: 58px;
}

.opportunities-table-button {
	box-sizing: border-box;

	width: 106px;
	height: 42px;

	background: #3e3e3e;
	mix-blend-mode: normal;
	opacity: 0.6;
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */

	background: linear-gradient(#3e3e3e, #3e3e3e) padding-box,
		linear-gradient(
				73.6deg,
				rgba(133, 255, 196, 0.6) 2.11%,
				rgba(92, 198, 255, 0.6) 42.39%,
				rgba(188, 133, 255, 0.6) 85.72%
			)
			border-box;
	border: 1.5px solid transparent;
	border-radius: 16px;

	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	/* identical to box height */

	text-align: center;
	text-transform: capitalize;

	color: #ffffff;
}
